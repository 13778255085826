import { graphql, PageProps } from "gatsby"
import React from "react"
import ActiviteitComponent from "../components/page/activiteit"
import { TemplateActiviteitQueryQuery } from "../__generated__/graphql-gatsby"

type IProps = PageProps<TemplateActiviteitQueryQuery>

const ActiviteitPage: React.FC<IProps> = ({ data: { wpActiviteit } }) => {
  const key = String(wpActiviteit?.uri)

  return <ActiviteitComponent data={wpActiviteit} sessionKey={key} />
}

export default ActiviteitPage

export const query = graphql`
  fragment PageBuilderActiviteitImage on WpActiviteit_PageBuilder_PageBuilder_Afbeelding {
    __typename
    maxWidth
    afbeelding {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  fragment PageBuilderActiviteitCTA on WpActiviteit_PageBuilder_PageBuilder_Cta {
    __typename
    titel
    subtitel
    uitlijningTitel
    linkKnop {
      title
      url
      target
    }
    uitlijningKnop
  }
  fragment PageBuilderActiviteitText on WpActiviteit_PageBuilder_PageBuilder_TekstEditor {
    __typename
    instellingen {
      achtergrond
      breedte
    }
    tekst
  }
  fragment PageBuilderActiviteitSpacer on WpActiviteit_PageBuilder_PageBuilder_Spacer {
    __typename
    hoogte
  }
  fragment PageBuilderActiviteitFaq on WpActiviteit_PageBuilder_PageBuilder_Faq {
    __typename
    titel
    tekst
    kleur
    standaardGesloten
    metaData
  }
  fragment PageBuilderActiviteitUitgelichteActiviteit on WpActiviteit_PageBuilder_PageBuilder_UitgelichteActiviteit {
    __typename
    flip
    onlyImg
    activiteit {
      ...UitgelichteActiviteit
    }
  }
  fragment PageBuilderActiviteitUitgelichteErvaring on WpActiviteit_PageBuilder_PageBuilder_UitgelichteErvaring {
    __typename
    flip
    onlyImg
    ervaring {
      ...UitgelichteErvaring
    }
  }
  fragment PageBuilderActiviteitLaatsteBerichten on WpActiviteit_PageBuilder_PageBuilder_LaatsteBerichten {
    __typename
    type {
      nodesOfPostTypes {
        nodes(limit: 5) {
          ...LaatsteBerichtenData
        }
      }
    }
    aantal
  }
  fragment PageBuilderActiviteitAgenda on WpActiviteit_PageBuilder_PageBuilder_Agenda {
    __typename
    weergave
    tags {
      id
    }
  }
  fragment PageBuilderActiviteitForm on WpActiviteit_PageBuilder_PageBuilder_Formulier {
    __typename
    nieuwsbriefFormulier
    addtext
    text
    redirect {
      url
    }
    form {
      ...Form
    }
  }
  fragment PageBuilderActiviteitOverzicht on WpActiviteit_PageBuilder_PageBuilder_OverzichtPaginas {
    __typename
    format
    sortering
    tags {
      id
    }
  }
  fragment PageBuilderActiviteitYoutube on WpActiviteit_PageBuilder_PageBuilder_Youtube {
    __typename
    titel
    toonTitel
    youtubeId
    aspectRatio
    tekst
    metTekst {
      plaatsTekst
      achtergrond
      breedteContainer
    }
  }
  fragment PageBuilderActiviteitFacebook on WpActiviteit_PageBuilder_PageBuilder_Facebook {
    __typename
    tabs
  }
  fragment PageBuilderActiviteitButtonsGroup on WpActiviteit_PageBuilder_PageBuilder_ButtonsGroup {
    __typename
    buttons {
      button {
        url
        title
        target
      }
    }
  }
  fragment PageBuilderActiviteitBlocks on WpActiviteit_PageBuilder_PageBuilder {
    ... on WpActiviteit_PageBuilder_PageBuilder_TekstEditor {
      ...PageBuilderActiviteitText
    }
    ... on WpActiviteit_PageBuilder_PageBuilder_Spacer {
      ...PageBuilderActiviteitSpacer
    }
    ... on WpActiviteit_PageBuilder_PageBuilder_Afbeelding {
      ...PageBuilderActiviteitImage
    }
    ... on WpActiviteit_PageBuilder_PageBuilder_Faq {
      ...PageBuilderActiviteitFaq
    }
    ... on WpActiviteit_PageBuilder_PageBuilder_Cta {
      ...PageBuilderActiviteitCTA
    }
    ... on WpActiviteit_PageBuilder_PageBuilder_UitgelichteActiviteit {
      ...PageBuilderActiviteitUitgelichteActiviteit
    }
    ... on WpActiviteit_PageBuilder_PageBuilder_UitgelichteErvaring {
      ...PageBuilderActiviteitUitgelichteErvaring
    }
    ... on WpActiviteit_PageBuilder_PageBuilder_LaatsteBerichten {
      ...PageBuilderActiviteitLaatsteBerichten
    }
    ... on WpActiviteit_PageBuilder_PageBuilder_OverzichtPaginas {
      ...PageBuilderActiviteitOverzicht
    }
    ... on WpActiviteit_PageBuilder_PageBuilder_Agenda {
      ...PageBuilderActiviteitAgenda
    }
    ... on WpActiviteit_PageBuilder_PageBuilder_Formulier {
      ...PageBuilderActiviteitForm
    }
    ... on WpActiviteit_PageBuilder_PageBuilder_Youtube {
      ...PageBuilderActiviteitYoutube
    }
    ... on WpActiviteit_PageBuilder_PageBuilder_Facebook {
      ...PageBuilderActiviteitFacebook
    }
    ... on WpActiviteit_PageBuilder_PageBuilder_ButtonsGroup {
      ...PageBuilderActiviteitButtonsGroup
    }
  }
  fragment PageBuilderActiviteit on WpActiviteit_PageBuilder {
    pageBuilder {
      ...PageBuilderActiviteitBlocks
    }
  }
  fragment AanvullendeData on WpActiviteit_ActiviteitFields_aanvullendeData {
    datum
    startTijd
    eindTijd
  }
  fragment ActiviteitFields on WpActiviteit_ActiviteitFields {
    datum
    startTijd
    eindTijd
    locatie
    kosten
    begeleider
    aanmeldenEmail
    aanmeldenTelefoon
    terugkerendeActiviteit
    verbergWanneer
    verbergDatum
    verbergTijd
    dates {
      text
      start_date
      end_date
    }
    heeftAanvullendeData
    aanvullendeData {
      ...AanvullendeData
    }
  }
  fragment wpActiviteit on WpActiviteit {
    id
    title
    uri
    seo {
      ...SEO
    }
    page_builder {
      ...PageBuilderActiviteit
    }
    activiteit_fields {
      ...ActiviteitFields
    }
  }
  query TemplateActiviteitQuery($id: String!) {
    wpActiviteit(id: { eq: $id }) {
      ...wpActiviteit
    }
  }
`
